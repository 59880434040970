import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9fdf92b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "card",
  dir: "ltr"
}
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { style: {"margin-right":"13px"} }
const _hoisted_4 = { style: {"color":"var(--el-text-color-secondary)","font-size":"10px"} }
const _hoisted_5 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_advanced_chat = _resolveComponent("vue-advanced-chat")!
  const _component_el_select_v2 = _resolveComponent("el-select-v2")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_vue_advanced_chat, {
          "current-user-id": _ctx.currentUserId,
          rooms: JSON.stringify(_ctx.rooms),
          messages: JSON.stringify(_ctx.messages),
          "room-actions": JSON.stringify(_ctx.roomActions),
          "show-reaction-emojis": "false",
          "show-audio": "false",
          "load-first-room": "true",
          "show-new-messages-divider": "false",
          "messages-loaded": _ctx.messagesLoaded,
          "rooms-loaded": _ctx.roomsLoaded,
          "message-actions": JSON.stringify(_ctx.messageActions),
          "text-messages": JSON.stringify(_ctx.textMessages),
          onAddRoom: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addNewParent($event.detail[0]))),
          onFetchMessages: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchMessages($event.detail[0]))),
          onSendMessage: _cache[2] || (_cache[2] = ($event: any) => (_ctx.sendMessage($event.detail[0]))),
          onOpenFile: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openFile($event.detail[0]))),
          onDeleteMessage: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deleteMessageHandler($event.detail[0])))
        }, null, 8, ["current-user-id", "rooms", "messages", "room-actions", "messages-loaded", "rooms-loaded", "message-actions", "text-messages"])
      ])
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.visibleAddParent,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.visibleAddParent) = $event)),
      title: "Add parent to chat",
      width: "50%",
      style: {"margin-top":"250px"},
      center: ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_el_button, { onClick: _ctx.cancelAddParent }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.handleAddParent
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Confirm ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("span", null, [
          _createVNode(_component_el_select_v2, {
            modelValue: _ctx.selectedParent,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedParent) = $event)),
            filterable: "",
            options: 
          _ctx.parentsData.map((p) => ({
            label:
              p.father.firstName +
              ' ' +
              p.father.lastName +
              ' & ' +
              p.mother.firstName +
              ' ' +
              p.mother.lastName,
            value: p.parentId,
            students: p.students,
          }))
        ,
            placeholder: "Select parent",
            style: {"width":"100%"},
            height: 300
          }, {
            default: _withCtx(({ item }) => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(item.label), 1),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t("course.students")) + ": " + _toDisplayString(item.students.join(" - ")), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}